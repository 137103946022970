import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgxAuthRoutingModule} from './auth-routing.module';
import {NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';
import {NbActionsModule, NbAlertModule, NbButtonModule, NbCheckboxModule, NbInputModule} from '@nebular/theme';
import {NgxLoginComponent} from './login/login.component';
import {environment} from '../../environments/environment';
import {TranslateModule} from '@ngx-translate/core';
import {NgxRequestPasswordComponent} from './request-password/request-password.component';


export interface NbAuthSocialLink {
  link?: string;
  url?: string;
  target?: string;
  title?: string;
  icon?: string;
}

const socialLinks: NbAuthSocialLink[] = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NgxAuthRoutingModule,
    TranslateModule.forChild(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email1',
          baseEndpoint: environment.cardholderBaseUrl ? environment.cardholderBaseUrl : null,
          login: {
            endpoint: '/oauth/token',
            method: 'post',
            defaultErrors: ['LOGIN_COMPONENT.LOGIN-DEFAULT-ERRORS'],
            defaultMessages: ['LOGIN_COMPONENT.LOGIN-DEFAULT-MESSAGES'],
          },
          refreshToken: {
            endpoint: '/oauth/token',
            method: 'post',
          },
          logout: {
            endpoint: '/auth/sign-out',
          },
          requestPass: {
            defaultErrors: ['LOGIN_COMPONENT.RESET-PASSWORD-DEFAULT-ERRORS'],
            defaultMessages: ['LOGIN_COMPONENT.RESET-PASSWORD-SUCCESS'],
            endpoint: '/oauth/users/password/reset',
          },
          token: {
            class: NbAuthJWTToken,
            key: 'access_token', // this parameter tells where to look for the token
          }
        })
      ],
      forms: {
        login: {
          redirectDelay: 200, // delay before redirect after a successful login, while success message is shown to the user
          strategy: 'email1',  // strategy id key.
          rememberMe: false,   // whether to show or not the `rememberMe` checkbox
          showMessages: {     // show/not show success/error messages
            success: true
          },
          socialLinks: socialLinks // social links at the bottom of a page
        },
        logout: {
          redirectDelay: 500,
          strategy: 'email1',
        },
        requestPassword: {
          redirectDelay: 200,
          strategy: 'email1',
          showMessages: {
            success: true,
            error: true,
          },
          socialLinks: socialLinks,
        },
        validation: {
          password: {
            required: true,
            minLength: 4,
            maxLength: 50,
          },
          email: {
            required: true,
          },
          fullName: {
            required: false,
            minLength: 4,
            maxLength: 50,
          }
        }
      },
    }),
    NbActionsModule,
  ],
  declarations: [
    NgxLoginComponent,
    NgxRequestPasswordComponent
  ]
})
export class NgxAuthModule {
}
