import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
export class ThemeMessageService {
    private subject = new BehaviorSubject<any>({ name: 'default' });

    sendMessage(message: string): void {
        this.subject.next({ name: message });
    }
    clearMessage(): void {
        this.subject.next({ name: '' });
    }
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
