import {AfterViewInit, Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {
  NbIconConfig,
  NbIconLibraries,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService
} from '@nebular/theme';
import {LayoutService} from '../../../@core/utils';
import {NbAuthJWTToken, NbAuthService, NbAuthToken} from '@nebular/auth';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthAddService} from '../../../auth/auth-add.service';
import {TranslateHelper} from '../../services/translate-helper.service';
import {Subscription} from 'rxjs/Subscription';

import {environment} from '../../../../environments/environment';
import {LangMessageService} from '../../../pages/lang-message.service';
import {ThemeMessageService} from '../../../pages/theme-message.service';
import {Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

// import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;

  @Input() position = 'normal';

  @Input() badgeText = 'Укр';

  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  subscription5: Subscription;
  subscription6: Subscription;

  //disabledIconConfig: NbIconConfig = { icon: 'settings-2-outline', pack: 'eva' };
  languageIconConfig: NbIconConfig = {icon: 'globe', pack: 'fas'};
  ukFlagIcon: NbIconConfig = {icon: 'ua', pack: 'flag-icon'};
  ruFlagIcon: NbIconConfig = {icon: 'ru', pack: 'flag-icon'};
  gbFlagIcon: NbIconConfig = {icon: 'gb', pack: 'flag-icon'};
  roFlagIcon: NbIconConfig = {icon: 'ro', pack: 'flag-icon'};
  exitIconConfig: NbIconConfig = {icon: 'sign-out-alt', pack: 'fas'};
  profileIconConfig: NbIconConfig = {icon: 'person-outline'};

  user: any;
  userObj: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  //userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  userMenu = [{title: 'Log out', icon: this.exitIconConfig, pathMatch: 'Log out'}];

  languageMenu = [{
    title: 'Укр',
    icon: environment.showLangIcon ? this.ukFlagIcon : null,
    pathMatch: 'Укр',
    nameLang: 'uk'
  }, {
    title: 'Рус', icon: environment.showLangIcon ? this.ruFlagIcon : null, pathMatch: 'Рус', nameLang: 'ru'
  },
    {title: 'Eng', icon: environment.showLangIcon ? this.gbFlagIcon : null, pathMatch: 'Eng', nameLang: 'en'},
    {title: 'Rom', icon: environment.showLangIcon ? this.roFlagIcon : null, pathMatch: 'Rom', nameLang: 'ro'}];
  evaIcons = [];

  /**
   *
   * @param sidebarService
   * @param menuService
   * @param themeService
   * @param layoutService
   * @param breakpointService
   * @param authService
   * @param authAddService
   * @param translateHelper
   * @param langMessageService
   * @param themeMessageService
   * @param router
   * @param translate
   * @param loca
   * @param iconsLibrary
   */
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: NbAuthService,
              private authAddService: AuthAddService,
              private translateHelper: TranslateHelper,
              private langMessageService: LangMessageService,
              private themeMessageService: ThemeMessageService,
              private router: Router,
              private translate: TranslateService,
              // private cookieService: CookieService,
              @Inject(LOCALE_ID) private loca: string,
              iconsLibrary: NbIconLibraries) {
    this.subscription2 = this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.userObj = token.getPayload(); // here we receive a payload from the token and assigne it to our `user` variable
        this.user = this.userObj.user_name;
      }
    });

    this.evaIcons = Array.from(iconsLibrary.getPack('eva').icons.keys())
      .filter(icon => icon.indexOf('outline') === -1);

    iconsLibrary.registerFontPack('fa', {packClass: 'fa', iconClassPrefix: 'fa'});
    iconsLibrary.registerFontPack('far', {packClass: 'far', iconClassPrefix: 'fa'});
    iconsLibrary.registerFontPack('fas', {packClass: 'fas', iconClassPrefix: 'fa'});
    iconsLibrary.registerFontPack('ion', {iconClassPrefix: 'ion'});
    iconsLibrary.registerFontPack('flag-icon', {iconClassPrefix: 'flag-icon'});

    this.applyLangResources();
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    if (environment.available_languages) {
      if (environment.available_languages instanceof Array) {
        if (environment.available_languages.length > 0) {
          this.languageMenu = this.languageMenu.filter(el => environment.available_languages.indexOf(el.nameLang) !== -1);
          // let tmpObjLangs = Object.assign(this.languageMenu);
          // for (let lang of environment.available_languages) {
          //   this.languageMenu.length = 0;
          //   this.languageMenu.push();
          // }
        }
      }
    }

    this.subscription3 = this.authService.getToken().subscribe((tokenObj: NbAuthToken) => {
      this.user = {username: tokenObj.getPayload().user_name, project: environment.project, password: ''};
    });

    //this.translateHelper.useLanguage('uk');
    //this.sendLangMessage('uk');
    this.translateHelper.useLanguage(environment.language);
    this.sendLangMessage(environment.language);
    //this.cookieService.set('locale', environment.language);

    if (environment.language === 'uk') {
      this.badgeText = 'Укр';
    } else if (environment.language === 'ru') {
      this.badgeText = 'Рус';
    } else if (environment.language === 'en') {
      this.badgeText = 'Eng';
    } else if (environment.language === 'ro') {
      this.badgeText = 'Rom';
    }

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.subscription4 = this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.subscription5 = this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
        this.themeMessageService.sendMessage(this.currentTheme);
      });

    this.subscription6 = this.menuService.onItemClick().subscribe((event) => {
      this.onItemSelection(event.item.pathMatch);
    });


  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscription) this.subscription.unsubscribe();
    if (this.subscription2) this.subscription2.unsubscribe();
    if (this.subscription3) this.subscription3.unsubscribe();
    if (this.subscription4) this.subscription4.unsubscribe();
    if (this.subscription5) this.subscription5.unsubscribe();
    if (this.subscription6) this.subscription6.unsubscribe();
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      //this.logger.debug('lang: ', event.lang);
      this.applyLangResources();
    });
  }

  /**
   *
   */
  private applyLangResources() {
    if (this.translate.currentLang === 'uk') {
      this.userMenu = [{
        title: this.translate.instant('COMMON.PROFILE'),
        icon: this.profileIconConfig,
        pathMatch: 'Profile'
      }, {
        title: this.translate.instant('COMMON.LOG_OUT'),
        icon: this.exitIconConfig,
        pathMatch: 'Log out'
      }];
    } else if (this.translate.currentLang === 'ru') {
      this.userMenu = [{
        title: this.translate.instant('COMMON.PROFILE'),
        icon: this.profileIconConfig,
        pathMatch: 'Profile'
      }, {
        title: this.translate.instant('COMMON.LOG_OUT'),
        icon: this.exitIconConfig,
        pathMatch: 'Log out'
      }];
    } else if (this.translate.currentLang === 'en') {
      this.userMenu = [{
        title: this.translate.instant('COMMON.PROFILE'),
        icon: this.profileIconConfig,
        pathMatch: 'Profile'
      }, {
        title: this.translate.instant('COMMON.LOG_OUT'),
        icon: this.exitIconConfig,
        pathMatch: 'Log out'
      }];
    } else if (this.translate.currentLang === 'ro') {
      this.userMenu = [{
        title: this.translate.instant('COMMON.PROFILE'),
        icon: this.profileIconConfig,
        pathMatch: 'Profile'
      }, {
        title: this.translate.instant('COMMON.LOG_OUT'),
        icon: this.exitIconConfig,
        pathMatch: 'Log out'
      }];
    }
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onItemSelection(title) {
    // this.cookieService.deleteAll('locale');
    if (title === 'Log out') {
      // Do something on Log out
      this.authAddService.logout();
    } else if (title === 'Profile') {
      // Do something on Profile
      this.router.navigate(['/pages/user-profile']);
    } else if (title === 'Укр') {
      this.badgeText = 'Укр';
      localStorage.setItem('locale', 'uk');
      // this.cookieService.set('locale', 'uk', 1, '/');
      this.translateHelper.useLanguage('uk');
      this.sendLangMessage('uk');
      this.loca = 'uk';
      //this.doRefreshLogOutBtn();
    } else if (title === 'Рус') {
      this.badgeText = 'Рус';
      localStorage.setItem('locale', 'ru');
      // this.cookieService.set('locale', 'ru', 1, '/');
      this.translateHelper.useLanguage('ru');
      this.sendLangMessage('ru');
      this.loca = 'ru';
      //this.langMessageService.sendNewLocale(this.loca);
      //this.doRefreshLogOutBtn();
    } else if (title === 'Eng') {
      this.badgeText = 'Eng';
      localStorage.setItem('locale', 'en');
      // this.cookieService.set('locale', 'en', 1, '/');
      this.translateHelper.useLanguage('en');
      this.sendLangMessage('en');
      this.loca = 'en';
      //this.doRefreshLogOutBtn();
    } else if (title === 'Rom') {
      this.badgeText = 'Rom';
      localStorage.setItem('locale', 'ro');
      // this.cookieService.set('locale', 'ro', 1, '/');
      this.translateHelper.useLanguage('ro');
      this.sendLangMessage('ro');
      this.loca = 'ro';
      //this.doRefreshLogOutBtn();
    }
  }

  changeLanguage() {
    //this.logger.debug("changeLanguage  = = ==");
  }

  sendLangMessage(language: string): void {
    // отправляем сообщения ко всем кто подписался через observable subject
    //this.langMessageService.sendMessage('Message from Home Component to App Component!');
    this.langMessageService.sendMessage(language);
  }

  clearLangMessage(): void {
    // очищаем сообщения
    this.langMessageService.clearMessage();
  }
}
