import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {NB_AUTH_OPTIONS, NbAuthService, NbRequestPasswordComponent} from '@nebular/auth';
import {environment} from '../../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ngx-request-password-page',
  styleUrls: ['./request-password.component.scss'],
  templateUrl: './request-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRequestPasswordComponent  extends NbRequestPasswordComponent {
  user: any = {
    email: '',
    project: environment.project
  };

  constructor(protected service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected cd: ChangeDetectorRef,
              protected router: Router,
              private logger: NGXLogger,
              protected translate: TranslateService) {
    super(service, options, cd, router);
  }

  requestPass(): void {
    this.logger.debug(this.user);
    super.requestPass();
  }
}
