import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {NbToastrService} from '@nebular/theme';
import {NGXLogger} from 'ngx-logger';


@Directive({
  selector: '[ngxHeaderMenuDerective]'
})
export class HeaderMenuDerectiveDirective  implements OnInit {


  constructor(el: ElementRef,
              private logger: NGXLogger) {
    this.logger.debug(el);
  }

  ngOnInit() {

  }

}
