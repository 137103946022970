import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthAddService {

    constructor(private router: Router) {
    }
    logout() {
        localStorage.removeItem('auth_app_token');
        localStorage.removeItem('zabbix_token');
        this.router.navigate(['auth/login']);
    }

    logoutSimple() {
        localStorage.removeItem('auth_app_token');
        localStorage.removeItem('zabbix_token');
    }
}
