import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root',
  })
export class TranslateHelper {

    private txt: string;

    constructor(private translate: TranslateService) {
        translate.setDefaultLang(environment.language);
        this.translate.use(environment.language);
        //this.logger.debug(this.translate.getDefaultLang() , this.translate.getBrowserLang());
    }

    useLanguage(language: string) {
        this.translate.use(language);
    }
}
