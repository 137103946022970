import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root',
  })
export class LangMessageService {
    private subject = new BehaviorSubject<any>({ text: environment.language });

    private subjectChangeLocale = new Subject<string>();

    sendMessage(message: string): void {
        this.subject.next({ text: message });
    }
    clearMessage(): void {
        this.subject.next({ text: '' });
    }
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    sendNewLocale(lang: string): void {
      this.subjectChangeLocale.next(lang);
    }

    getNewLocale(): Observable<any> {
      return this.subjectChangeLocale.asObservable();
    }

}
