import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';

import {Observable} from 'rxjs/internal/Observable';
import {catchError, timeout} from 'rxjs/operators';

import {NbGlobalPhysicalPosition, NbToastrService} from '@nebular/theme';
import {throwError, TimeoutError} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  /**
   *
   * @param router
   * @param toastrService
   * @param logger
   */
  constructor(private router: Router,
              private toastrService: NbToastrService,
              private logger: NGXLogger) {
    //
  }

  /**
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (localStorage.getItem('locale') == null) {
      localStorage.setItem('locale', environment.language);
    }

    req = req.clone({
      setHeaders: {
        'Accept-Language': localStorage.getItem('locale'),
      },
    });

    return next.handle(req).pipe(timeout(300000), catchError(err =>
      this.handleAuthError(err)
    ));
  }

  /**
   *
   * @param response
   * @private
   */
  private handleAuthError(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    this.logger.debug('ApiInterceptor handleAuthError response: ', response);

    if (response === null || response === undefined || response instanceof TimeoutError) {
      this.toastrService.show(
        'Network connection timeout',
        `Error`,
        {
          destroyByClick: false,
          duplicatesBehaviour: undefined,
          duration: 8000,
          limit: 0,
          position: NbGlobalPhysicalPosition.TOP_RIGHT,
          preventDuplicates: false,
          status: 'danger'
        });
    }

    if (response.status === 401) {
      this.logger.debug('ApiInterceptor handleAuthError this.nav', this.router);

      if (this.router !== undefined && this.router !== null) {
        localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    }

    return throwError(response);
  }
}
