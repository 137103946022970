import {Component, Inject, ChangeDetectorRef, OnInit} from '@angular/core';
import {NbLoginComponent, NB_AUTH_OPTIONS, NbAuthService, NbAuthOptions} from '@nebular/auth';
import { Router } from '@angular/router';
import { AuthAddService } from '../auth-add.service';
import { environment } from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
})
export class NgxLoginComponent extends NbLoginComponent implements OnInit {

  user: any = {
    username: environment.cardholderBaseLogin,
    password: environment.cardholderBasePassword,
    project: environment.project
  };

  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options: NbAuthOptions = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private authAddService: AuthAddService) {
    super(service, options, cd, router);

  }

  ngOnInit(): void {
    if (localStorage.getItem != null) {
      this.authAddService.logoutSimple();
    }
  }


  login() {
    super.login();
  }

}
