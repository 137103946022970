import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {tap} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, Subscription} from 'rxjs';
import * as moment from 'moment';
import {NGXLogger} from 'ngx-logger';


@Injectable()
export class AuthGuard implements CanActivate {

  subscription1: Subscription;

  constructor(private authService: NbAuthService,
              private router: Router,
              private http: HttpClient,
              private logger: NGXLogger) {
  }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          } else {
            let response1: Observable<any> = this.authService.getToken();
            let response2: Observable<any> = this.http.get<Observable<any>>(environment.cardholderBaseUrl + `/config/hd`);
            let response3: Observable<any> = this.http.get<Observable<any>>(environment.cardholderBaseUrl + `/config/current/?param=report.currency`);
            this.subscription1 = forkJoin([response1, response2, response3]).subscribe(responseList => {
              //this.logger.debug(responseList);
              let tokenObj: NbAuthJWTToken = responseList[0];
              this.logger.debug('xxx');
              this.logger.debug(tokenObj);
              //this.logger.debug(tokenObj.getTokenExpDate());
              let expDate = moment(tokenObj.getTokenExpDate());
              this.logger.debug('exp data = ', expDate.format('DD.MM.YYYY HH:mm'));
              this.logger.debug('token is valid = ', tokenObj.isValid());
              this.logger.debug('authorities = ', tokenObj.getPayload().authorities);
              let settings = responseList[1];
              this.logger.debug('----Testing-----');
              this.logger.debug('----Prev Env-----', environment);
              this.logger.debug('----settings-----', settings);
              environment.zabbix = settings.zabbix;
              this.logger.debug('----environment.zabbix-----', environment.zabbix);
              environment.report_currency = responseList[2].value;
              environment.rowsInTable = settings.rowsInTable;
              environment.countMonth = settings.countMonth;
              this.logger.debug('----New Env-----', environment);
            }, error => {
              if (error.status === 401) {
                localStorage.removeItem('auth_app_token');
                localStorage.removeItem('curuser');
                localStorage.removeItem('zabbix_token');
                this.router.navigate(['auth/login']);
              } else {

                this.logger.error(error);
              }
            });

            // this.authService.getToken().subscribe((tokenObj: NbAuthJWTToken)  => {
            //   this.logger.debug(tokenObj.getTokenExpDate());
            //   let expDate  =  moment(tokenObj.getTokenExpDate());
            //   this.logger.debug('exp data = ', expDate.format('DD.MM.YYYY HH:mm'));
            //   this.logger.debug('token is valid = ', tokenObj.isValid());
            //   this.logger.debug('authorities = ' , tokenObj.getPayload().authorities);
            //   this.logger.debug(environment);
            // });
          }
        }),
      );
  }

  changeCurrency() {
    let response1: Observable<any> = this.http.get<Observable<any>>(environment.cardholderBaseUrl + `/config/current/?param=report.currency`);

    this.subscription1 = forkJoin([response1]).subscribe(responseList => {
      environment.report_currency = responseList[0].value;
      console.log(environment.report_currency);
    }, error => {
      if (error.status === 401) {
        localStorage.removeItem('auth_app_token');
        localStorage.removeItem('curuser');
        localStorage.removeItem('zabbix_token');
        this.router.navigate(['auth/login']);
      } else {
        this.logger.error(error);
      }
    });
  }
}
