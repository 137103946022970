import { Component } from '@angular/core';
import {versionInfo} from '../../../version-info';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
      <span class="created-by" style="color: #4B0082;"> {{'footercomponent.created' | translate }} ♥ {{'footercomponent.by' | translate }} <b><a href='{{develop_company_link}}' target="_blank">{{develop_company}}</a></b> {{datCur | date  : 'yyyy'}}   <small>~{{project}}~</small></span>
      <span class="add-build-version">version: {{version}} build: {{buildHash}}</span>
  `,
})
export class FooterComponent {
  public datCur: Date = new Date();

  version;
  buildHash;
  project;
  develop_company;
  develop_company_link;

  constructor() {
    this.version = environment.version;
    this.buildHash = versionInfo.hash;
    this.project = environment.project;

    this.develop_company = environment.develop_company;
    this.develop_company_link = environment.develop_company_link;
  }
}
